import config from '../../../utils/config';
import { filterTypes, holdStatuses, releaseStatuses } from './data';

const ORIGIN = config.origin;

export const validateReleaseNumber = async (currentUser, releaseNumber) => {
    const dataToSend = {
        authentication: {
            userId: currentUser.authentication.userId,
            userEmail: currentUser.authentication.userEmail,
            userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
        },
        releaseNumber,
    };
    const response = await fetch(`${ORIGIN}/wms/trace/check-release-data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
    });

    if (!response.ok) throw new Error('Failed to get release data');

    const responseData = await response.json();
    return responseData;
};

export const fetchReleaseData = async (currentUser) => {
    const dataToSend = {
        authentication: {
            userId: currentUser.authentication.userId,
            userEmail: currentUser.authentication.userEmail,
            userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
        },
    };
    const response = await fetch(`${ORIGIN}/wms/trace/get-release-data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
    });

    if (!response.ok) throw new Error('Failed to get release data');

    const responseData = await response.json();

    return responseData;
};

export const isReleaseFiltered = (release, filters) => {
    if (filters.length === 0) {
        // No filters applied; include all releases
        return false;
    }

    const releaseStatus = releaseStatuses[release.status];
    const onHold = isOnHold(release);
    const paperworkOnly = isPaperworkOnly(release);
    const customerName = release.customerName;

    // If 'ALL' is among the selected filters, include all releases
    if (filters.includes(filterTypes.ALL)) {
        return false;
    }

    // Check if the release matches any of the selected filters
    let matchesFilter = false;

    for (const filter of filters) {
        // Check for 'ON_HOLD' filter
        if (filter === filterTypes.ON_HOLD && onHold) {
            matchesFilter = true;
            break;
        }

        // Check for 'PPWK' filter
        if (filter === filterTypes.PPWK && paperworkOnly) {
            matchesFilter = true;
            break;
        }

        // Check for 'AIR_CERT' filter
        if (filter === filterTypes.AIR_CERT && customerName === 'AIR-CERT, LLC') {
            matchesFilter = true;
            break;
        }

        // Check if the release status matches the filter
        if (filter === releaseStatus) {
            matchesFilter = true;
            break;
        }
    }

    // Include the release if it matches any filter; otherwise, exclude it
    return !matchesFilter;
};

export const getSanitizedInput = (input) => {
    const doesInputOnlyHaveNumbers = /^\d+$/.test(input);
    const sanitizedSearch = doesInputOnlyHaveNumbers ? `RLS-${input.toUpperCase()}` : input.toUpperCase();
    return sanitizedSearch;
};

const isOnHold = (release) => {
    if (release.creditStatus === holdStatuses.ON_HOLD) return true;
    if (release.exportStatus === holdStatuses.ON_HOLD) return true;
};

const isPaperworkOnly = (release) => {
    if (release.paperwork) return true;
};
