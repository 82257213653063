import { useState, useEffect, useContext } from 'react';
import { pageNames } from './utils/data';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import {
    getInvPhotos,
    sendChatterMessage,
    submitTracePhoto,
    updateHeroPhotos,
    updateReleaseLineStatus,
} from './utils/guided-interface-functions';
import { base64ToBlob } from '../../../utils/commonAPICalls';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { GuidedInterfaceContext } from './context/GuidedInterfaceContext.js';
import PhysicalCamera from './PhysicalCamera';
import LoadingSpinner from '../../../components/LoadingSpinner';

export default function Physical({ currentReleaseLine, currentUser, handlePageChange, releaseName, returnToReleaseLines }) {
    const [submissionStatus, setSubmissionStatus] = useState({
        isSubmitting: false,
        photos: '',
        heroImages: '',
        chatter: '',
        inspectionStatus: '',
        retry: 0,
        type: '',
    });
    const [loading, setLoading] = useState({
        photos: true,
        camera: true,
    });
    const [buttonLoading, setButtonLoading] = useState(null);

    const [reviewRequest, setReviewRequest] = useState('');

    // Camera State
    const [showCamera, setShowCamera] = useState(false);
    const [videoStream, setVideoStream] = useState(null);
    const [cameras, setCameras] = useState([]);
    const [currentCameraIndex, setCurrentCameraIndex] = useState(0);
    const [photos, setPhotos] = useState({ toAdd: [], current: [], added: [] });

    const { partInspectionSeconds, partInspectionTimeDisplay, setShowNotification, startTimer, stopTimer } =
        useContext(GuidedInterfaceContext);

    const findChangedHeroPhotos = (photos) => {
        return photos.filter(
            (photo) =>
                (photo.isHero && !currentReleaseLine.heroPictureNames.includes(photo.name)) ||
                (!photo.isHero && currentReleaseLine.heroPictureNames.includes(photo.name))
        );
    };

    const handlePhotoSubmissions = async (isAccepted) => {
        if (photos.added.length) {
            handlePhotoUpload(photos.added, isAccepted); //? ALSO UPDATES HERO STATUSES
        }

        const photosChanged = findChangedHeroPhotos(photos.current);
        if (photosChanged.length) {
            handleHeroPhotoUpdate(photosChanged, isAccepted);
        }
    };

    const handleSaveStatusAndTime = async (status) => {
        setSubmissionStatus((prev) => ({ ...prev, inspectionStatus: 'pending' }));
        const response = await updateReleaseLineStatus(
            status,
            'physical',
            currentReleaseLine.releaseLineName,
            releaseName,
            partInspectionSeconds,
            currentUser
        );
        if (!response) {
            setSubmissionStatus((prev) => ({ ...prev, inspectionStatus: 'fail', retry: prev.retry + 1 }));
        } else {
            setSubmissionStatus((prev) => ({ ...prev, inspectionStatus: 'success' }));
        }
    };

    const handlePhotoUpload = async (photoData, isAccepted) => {
        setSubmissionStatus((prev) => ({ ...prev, photos: 'pending' }));
        const responses = [];
        for (const photo of photoData) {
            const blob = base64ToBlob(photo.url.replace('data:image/png;base64,', ''), 'image/png');
            const isHero = photo.isHero;
            const authData = {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            };
            const response = await submitTracePhoto(blob, isHero, authData, currentReleaseLine, isAccepted, releaseName);

            responses.push({ ...photo, url: photo.url, uploaded: response.success ? 1 : 0 });
        }
        const failedUploads = responses.filter((photo) => photo.uploaded === 0);

        if (failedUploads.length) {
            setSubmissionStatus((prev) => ({ ...prev, photos: 'fail', retry: prev.retry + 1 }));
        } else setSubmissionStatus((prev) => ({ ...prev, photos: 'success' }));
        setPhotos({
            ...photos,
            toAdd: [],
            added: [...failedUploads],
        });
    };

    const handleHeroPhotoUpdate = async (photosChanged, isAccepted) => {
        setSubmissionStatus((prev) => ({ ...prev, heroImages: 'pending' }));
        const response = await updateHeroPhotos(
            photosChanged,
            currentUser,
            { ...currentReleaseLine, releaseName },
            isAccepted
        );
        if (!response) {
            setSubmissionStatus((prev) => ({ ...prev, heroImages: 'fail', retry: prev.retry + 1 }));
        } else {
            setSubmissionStatus((prev) => ({ ...prev, heroImages: 'success' }));
        }
    };

    const handleChatterUpload = async () => {
        setSubmissionStatus((prev) => ({ ...prev, chatter: 'pending' }));
        const response = await sendChatterMessage(currentUser, currentReleaseLine, reviewRequest);
        if (!response.success) {
            setSubmissionStatus((prev) => ({ ...prev, chatter: 'fail', retry: prev.retry + 1 }));
        } else {
            setSubmissionStatus((prev) => ({ ...prev, chatter: 'success' }));
        }
    };

    const navigateBack = () => {
        !showCamera ? handlePageChange(pageNames.RELEASE_LINES) : setShowCamera(false);
    };

    const submitRejection = async () => {
        // Set the rejected button to start loading spinner
        setButtonLoading('rejected');
        setSubmissionStatus((prev) => ({ ...prev, isSubmitting: true, type: 'rejected' }));
        // photo uploads and hero image updates
        handlePhotoSubmissions(false);
        handleChatterUpload();
        // time and status updates
        handleSaveStatusAndTime('rejected');
    };

    const submitApproval = async () => {
        // Set the accepted button to start loading spinner
        setButtonLoading('accepted');

        setSubmissionStatus((prev) => ({ ...prev, isSubmitting: true, type: 'complete' }));
        //photo uploads and hero image updates
        handlePhotoSubmissions(true);
        // status and time updates
        handleSaveStatusAndTime('complete');
    };

    useEffect(() => {
        const timer = startTimer();

        getInvPhotos(currentReleaseLine.invLineName, currentReleaseLine.tracePicturesLink, currentUser).then(
            (dropboxPhotos) => {
                setPhotos((prev) => ({
                    ...prev,
                    current: dropboxPhotos?.photos.map((photo) => ({
                        url: `data:image/png;base64, ${photo.data}`,
                        name: photo.name,
                        isHero: currentReleaseLine.heroPictureNames.includes(photo.name),
                    })),
                }));
                setLoading((prev) => ({ ...prev, photos: false }));
            }
        );

        return () => stopTimer(timer);
    }, []);

    // Retry submission - each fail increments the retry + 1, retry button increments by 10 and triggers retry
    useEffect(() => {
        if (submissionStatus.retry > 10) {
            const isAccepted = submissionStatus.type === 'complete';
            if (submissionStatus?.photos === 'fail') {
                handlePhotoUpload(photos.added, isAccepted);
            }
            if (submissionStatus.heroImages === 'fail') {
                const photosChanged = findChangedHeroPhotos(photos.current);
                updateHeroPhotos(photosChanged, currentUser, { ...currentReleaseLine, releaseName }, isAccepted);
            }
            if (submissionStatus.chatter === 'fail') {
                sendChatterMessage(currentUser, currentReleaseLine, reviewRequest);
            }
            if (submissionStatus.inspectionStatus === 'fail') {
                handleSaveStatusAndTime(isAccepted ? 'complete' : 'rejected');
            }
        }
    }, [submissionStatus.retry]);

    // Submission status check
    useEffect(() => {
        let failure = false;
        let processing = false;
        for (const key in submissionStatus) {
            if (submissionStatus[key] === 'pending') processing = true;
            if (submissionStatus[key] === 'fail') failure = true;
        }
        if (!submissionStatus.isSubmitting || processing) return;
        if (failure) {
            if (submissionStatus.retry < 10) {
                setShowNotification({
                    shown: true,
                    status: 'error',
                    title: 'Submission Failed',
                    message: 'Please retry submission',
                });
            } else {
                setShowNotification({
                    shown: true,
                    status: 'error',
                    title: 'Submission Failed',
                    message: 'No retry attempts remaining, returning to previous page in 5 seconds',
                });
                setTimeout(() => {
                    returnToReleaseLines();
                }, 5000);
            }
        }
        if (!failure && !processing) {
            setShowNotification({
                shown: true,
                status: 'success',
                title: 'Submission Successful',
                message: 'You will be returned to the previous page in 5 seconds',
            });
            setTimeout(() => {
                returnToReleaseLines();
            }, 5000);
        }
    }, [submissionStatus?.photos, submissionStatus.heroImages, submissionStatus.chatter, submissionStatus.inspectionStatus]);

    return (
        <div className='flex flex-col items-center px-8'>
            <header className='flex w-full h-10 mt-10'>
                <button
                    className='z-10 flex items-center justify-center w-24 h-10 px-3 my-auto text-lg text-white rounded-md bg-blue-primary hover:bg-blue-secondary'
                    onClick={navigateBack}
                >
                    <ArrowLeftIcon className='w-6 h-6 mr-2' />
                    <span>Back</span>
                </button>
                <div className='flex-grow text-center -ml-36'>
                    <h1 className='text-4xl font-bold'>
                        Physical Inspection for{' '}
                        <a
                            className='text-blue-900 underline hover:text-blue-400'
                            href={currentReleaseLine.invLineLink}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {currentReleaseLine.invLineName}
                        </a>
                    </h1>
                </div>
            </header>

            <section className='w-full p-2 mt-4 max-w-8xl'>
                {/* //? This will be needed once we want to start displaying the times on the page (not on initial release)
                <div>
                    <p className='text-lg'>
                        Total Timer Per Part:
                        <span className={`font-bold ${partInspectionSeconds > 300 ? 'text-red-600' : 'text-green-600'}`}>
                            {partInspectionTimeDisplay}
                        </span>
                    </p>
                    <p>
                        Department Time/Part: <span className='font-bold'>{'-DEPARTMENT_TIME_AVERAGE-'}</span>
                    </p>
                </div> */}

                <div className='relative flex flex-col items-center bg-white rounded-lg shadow-md'>
                    {!showCamera ? (
                        <>
                            <div className='w-3/4 p-2 text-lg'>
                                <div className='flex justify-between p-2 font-bold'>
                                    <h3>Inspection Requirement</h3>
                                    <h3>Pass Criteria</h3>
                                </div>
                                <div className='flex justify-between p-2 border-b'>
                                    <p>Inspect the part for damage and verify against condition code</p>
                                    <p>{currentReleaseLine.conditionCodeName}</p>
                                </div>
                                <div className='flex justify-between p-2 border-b'>
                                    <p>Verify part number against order</p>
                                    <p>{currentReleaseLine.partName}</p>
                                </div>
                                {currentReleaseLine.serialNumber !== 'N/A' ? (
                                    <div className='flex justify-between p-2 border-b'>
                                        <p>Verify serial number against order</p>
                                        <p>{currentReleaseLine.serialNumber}</p>
                                    </div>
                                ) : null}
                                {currentReleaseLine.requiresExpRelubeDate ? (
                                    <div className='flex justify-between p-2 border-b'>
                                        <p>Confirm meets Expiration/Relube date requirements</p>
                                        {/* Yes is a placeholder for Date */}
                                        <p>Yes</p>
                                    </div>
                                ) : null}
                                {currentReleaseLine.isConnector ? (
                                    <div className='flex justify-between p-2 border-b'>
                                        <p>Has correct number of pins</p>
                                        {/* Yes is a placeholder for # of pins */}
                                        <p>Yes</p>
                                    </div>
                                ) : null}
                                {currentReleaseLine.darRequired ? (
                                    <>
                                        {currentReleaseLine.oemLabel ? (
                                            <div className='flex justify-between p-2 border-b'>
                                                <p>OEM Label</p>
                                                <p>Present</p>
                                            </div>
                                        ) : null}
                                        {currentReleaseLine.oemPackaging ? (
                                            <div className='flex justify-between p-2 border-b'>
                                                <p>OEM Packaging</p>
                                                <p>Present</p>
                                            </div>
                                        ) : null}
                                        {currentReleaseLine.partNumberOnPart ? (
                                            <div className='flex justify-between p-2 border-b'>
                                                <p>Part Number on Part</p>
                                                <p>Present</p>
                                            </div>
                                        ) : null}
                                    </>
                                ) : null}
                            </div>
                            <button
                                className='m-2 bg-green-600 rounded-lg p-2 text-white text-center w-[97%] font-medium'
                                onClick={() => setShowCamera(true)}
                            >
                                This Part has met all inspection requirements
                            </button>
                        </>
                    ) : (
                        <div className='w-full p-3 mx-2 mt-2'>
                            <PhysicalCamera
                                cameras={cameras}
                                currentCameraIndex={currentCameraIndex}
                                loading={loading}
                                photos={photos}
                                setCameras={setCameras}
                                setCurrentCameraIndex={setCurrentCameraIndex}
                                setLoading={setLoading}
                                setPhotos={setPhotos}
                                setVideoStream={setVideoStream}
                                submitApproval={submitApproval}
                                videoStream={videoStream}
                                buttonLoading={buttonLoading}
                            />
                        </div>
                    )}
                </div>
                <div className='flex flex-col p-3 mt-10 mb-4 bg-gray-100 rounded-lg shadow-lg'>
                    <h2 className='mt-4 text-3xl font-bold'>Request Review</h2>
                    <input
                        type='text'
                        className='p-1 mt-3 border border-gray'
                        placeholder='Enter review request reason...'
                        value={reviewRequest}
                        onChange={(e) => setReviewRequest(e.target.value)}
                    />
                    <button
                        disabled={reviewRequest.length < 1 || buttonLoading === 'rejected'}
                        className='self-center w-full p-2 mt-3 font-medium text-center text-white bg-red-600 rounded-lg cursor-pointer'
                        onClick={submitRejection}
                    >
                        {buttonLoading === 'rejected' ? (
                            <div className='flex items-center justify-center'>
                                <LoadingSpinner className='w-44' spinnerSize={'8'} color={'text-white'} />
                            </div>
                        ) : (
                            'Submit Request'
                        )}
                    </button>
                </div>
            </section>
            {/* Submission overlay/modal */}
            {submissionStatus.isSubmitting ? (
                <div className='fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50'>
                    <div className='flex justify-center bg-white rounded-lg w-96 h-96'>
                        <div className='items-center justify-center w-9/12 backdrop:flex'>
                            <h2 className='my-10 text-2xl font-bold'>Submission Status</h2>
                            {photos.added.length || submissionStatus?.photos === 'success' ? (
                                <SubmissionStep text='Uploading Photos' status={submissionStatus?.photos} />
                            ) : null}
                            {findChangedHeroPhotos(photos.current).length ? (
                                <SubmissionStep text='Updating Hero Images' status={submissionStatus.heroImages} />
                            ) : null}
                            {submissionStatus.type === 'rejected' ? (
                                <SubmissionStep text='Adding Chatter Message' status={submissionStatus.chatter} />
                            ) : null}
                            <SubmissionStep text='Updating Release Line Status' status={submissionStatus.inspectionStatus} />
                            {submissionStatus.retry > 0 && submissionStatus.retry < 10 ? (
                                <div className='flex items-center justify-center'>
                                    <button
                                        className='w-3/12 p-2 mt-3 font-medium text-center text-white bg-red-600 rounded-lg'
                                        onClick={() =>
                                            setSubmissionStatus((prev) => ({ ...submissionStatus, retry: prev.retry + 10 }))
                                        }
                                    >
                                        Retry
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

function SubmissionStep({ text, status }) {
    return (
        <div className='flex items-center justify-between my-3 border-b'>
            <p>{text}</p>
            {status !== 'fail' ? (
                <CheckCircleIcon
                    className={
                        'hover:cursor-pointer w-8 h-8 rounded-full duration-100 ' +
                        (status === 'success'
                            ? 'bg-green-500 text-white hover:bg-green-400'
                            : status === 'fail'
                            ? 'bg-red-500 text-white hover:bg-red-400'
                            : 'bg-white hover:bg-gray-200 border-2 border-gray-500 text-white hover:text-gray-200')
                    }
                />
            ) : (
                <XMarkIcon className='w-8 h-8 text-red-600' />
            )}
        </div>
    );
}
