import React, { useState } from 'react';
import config from '../../utils/config';
import { useAuth } from '../context/wms-context';

export default function WMSShipping() {
    const { currentUser } = useAuth();
    const ORIGIN = config.origin;
    const [releaseNumber, setReleaseNumber] = useState('');

    const handleSubmit = async (e) => {
        const company = currentUser.authentication.userCompany || localStorage.getItem('company');
        const shippingQrURL = `${ORIGIN}/print/show-zencraft-release?name=${releaseNumber}&company=${company}`;
        window.open(shippingQrURL, '_blank').focus();
    };

    return (
        <div className='flex h-[calc(100vh-64px)] items-center justify-center'>
            <form onSubmit={handleSubmit} className='flex flex-col items-center'>
                <label htmlFor='releaseNumber' className='mb-2 text-lg'>
                    Release Number
                </label>
                <input
                    id='releaseNumber'
                    type='text'
                    value={releaseNumber}
                    onChange={(e) => setReleaseNumber(e.target.value)}
                    className='p-2 border border-gray-300 rounded'
                    autoFocus
                />
                <button type='submit' className='mt-4 px-4 py-2 bg-blue-primary text-white rounded'>
                    Submit
                </button>
            </form>
        </div>
    );
}
