// React
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import config from '../utils/config';
import GenericNavbar from '../components/GenericNavbar';
import LoadingSpinner from '../components/LoadingSpinner';
import logError from '../utils/errorUtil';
import SimpleLogo from '../assets/images/simpleLogo.jpg';

export default function CmmIpcDocument() {
    const ORIGIN = config.origin;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryParamsObject = {};
    queryParams.forEach((value, key) => {
        queryParamsObject[key] = value;
    });
    const [loadingPage, setLoadingPage] = useState(true);
    const [loadedDocument, setLoadedDocument] = useState({
        document: null,
        error: false,
        tableOfContents: [],
    });

    useEffect(() => {
        if (loadingPage) {
            fetchDocument();
        }
    }, []);

    async function fetchDocument() {
        try {
            const partNumber = queryParamsObject?.partNumber;
            const token = queryParamsObject?.fahPAIsfo;
            const id = queryParamsObject?.id;

            const response = await fetch(`${ORIGIN}/document/cmmipc?fahPAIsfo=${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    partNumber,
                    id,
                }),
            });

            if (!response.ok) {
                throw new Error('Error fetching document');
            }

            if (response.status === 204) {
                setLoadedDocument({
                    document: null,
                    error: false,
                });
            } else {
                const data = await response.json();

                setLoadedDocument({
                    document: data.document,
                    tableOfContents: data.tableOfContents,
                    error: false,
                });
            }
        } catch (error) {
            logError(error, 'CMM+IPC fetchDocument');
            setLoadedDocument({
                document: null,
                error: true,
            });
        } finally {
            setLoadingPage(false);
        }
    }

    return (
        <div className='flex flex-col min-h-screen'>
            <GenericNavbar title='CMM+IPC' />
            {loadingPage ? (
                <div className='flex items-center justify-center flex-grow'>
                    <div className='text-center -mt-28'>
                        <LoadingSpinner />
                        <p className='pt-6 font-semibold text-2xl'>Loading Documents...</p>
                    </div>
                </div>
            ) : loadedDocument.error === true ? (
                <div className='flex items-center justify-center flex-grow'>
                    <main className='bg-white px-6 lg:px-8'>
                        <div className='text-center'>
                            <div className='flex justify-center'>
                                <img className='h-40 w-auto' src={SimpleLogo} alt='Pacific Air Logo' />
                            </div>
                            <p className='text-base font-semibold text-indigo-600'>404</p>
                            <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
                                Error loading document.
                            </h1>
                            <p className='mt-6 text-base leading-7 text-gray-600'>
                                There was an error loading the document from this URL.
                            </p>
                        </div>
                    </main>
                </div>
            ) : (
                <div className='flex flex-grow p-4'>
                    <div className='w-[30%] border-gray-300'>
                        <h2 className='font-semibold text-2xl mb-6 underline text-center'>Table of Contents</h2>
                        <div className='overflow-auto h-full'>
                            <table className='w-full border'>
                                <thead className='bg-blue-primary border border-blue-primary sticky top-0'>
                                    <tr>
                                        <th className='p-2 text-left text-white'>Page #</th>
                                        <th className='p-2 text-left text-white'>Document Name</th>
                                        <th className='p-2 text-right text-white'>Document Page #</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loadedDocument.tableOfContents?.map((item, index) => (
                                        <tr key={index} className={`border-b ${index % 2 === 1 ? 'bg-gray-200' : ''}`}>
                                            <td className='pl-5 pr-2 py-2'>{item.page}</td>
                                            <td className='p-2'>
                                                <a
                                                    href={item.documentLink}
                                                    className='text-blue-500 hover:underline'
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                >
                                                    {item.documentName}
                                                </a>
                                            </td>
                                            <td className='p-2 text-right'>{item.fromPage}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='w-[70%] pl-4 flex-grow flex items-center justify-center'>
                        <div className='flex h-full w-full flex-col items-center justify-center'>
                            <h2 className='font-semibold text-2xl mb-6 text-center'>
                                Part Number: {queryParamsObject?.partNumber}
                            </h2>
                            <div className='border-2 border-gray-300 h-full w-full flex items-center justify-center'>
                                {loadedDocument?.document ? (
                                    <embed
                                        src={`data:application/pdf;base64,${loadedDocument.document}`}
                                        type='application/pdf'
                                        className='w-full h-full'
                                    />
                                ) : (
                                    <p className='text-xl font-semibold underline text-center'>
                                        No document found for this part number.
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
