// React and Modules
import React, { useState, useEffect, useContext } from 'react';
import logError from '../../../utils/errorUtil.js';
import { ArrowLeftIcon, CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';

// Utils
import { useAuth } from '../../context/wms-context.js';
import LoadingSpinner from '../../../components/LoadingSpinner';

// Other Pages
import Physical from './Physical';
import Forms from './forms/Forms.js';
import Trace from './Trace';
import { pageNames } from './utils/data';
import { getReleaseLineData, printAllPickingLabels } from './utils/guided-interface-functions';
import { GuidedInterfaceContext } from './context/GuidedInterfaceContext.js';
import { FormContext } from './context/FormContextWrapper.js';

export default function ReleaseLines({ releaseName, clearSelectedRelease, releaseData }) {
    const { currentUser } = useAuth();
    const { partInspectionSeconds, partInspectionTimeDisplay, setShowNotification, startTimer, stopTimer } =
        useContext(GuidedInterfaceContext);
    const { setCurrentRelease } = useContext(FormContext);

    const [selectedPage, setSelectedPage] = useState(pageNames.RELEASE_LINES);
    const [releaseLineData, setReleaseLineData] = useState([]);
    const [loadingReleaseLines, setLoadingReleaseLines] = useState(true);
    const [currentReleaseLine, setCurrentReleaseLine] = useState(null);
    const [pickingLabelsLoading, setPickingLabelsLoading] = useState(false);
    const [documentsToPrint, setDocumentsToPrint] = useState({
        documentsFetched: false,
        fetchingDocuments: false,
        ownerCode: null,
        partRecord: null,
        traceTag: null,
        oneClickBinder: null,
    });

    useEffect(() => {
        const timer = startTimer();
        if (!releaseLineData?.length) {
            getReleaseLines();
            setCurrentRelease(releaseName);
        }
        return () => stopTimer(timer);
    }, []);

    const returnToReleaseLines = () => {
        handlePageChange(pageNames.RELEASE_LINES);
        getReleaseLines();
        setCurrentReleaseLine(null);
    };

    async function getReleaseLines() {
        try {
            const response = await getReleaseLineData(currentUser, releaseName);

            if (!response?.length) {
                setShowNotification({
                    shown: true,
                    status: 'error',
                    title: 'No Valid Release Lines',
                    message: 'No valid allocated Inventory Lines found on Release',
                });

                clearSelectedRelease();
                return;
            }

            setReleaseLineData(response);
        } catch (error) {
            setShowNotification({
                shown: true,
                status: 'error',
                title: 'Error Loading Release Lines',
                message:
                    'There was an error loading the release lines. Please try again and if this issue persists contact your manager.',
            });
            logError('Error getting release line data:', error);
            clearSelectedRelease();
        } finally {
            setLoadingReleaseLines(false);
        }
    }

    const handlePageChange = (page, lineId) => {
        setSelectedPage(page);

        const matchingLine = releaseLineData.find((line) => line.releaseLineId === lineId);
        setCurrentReleaseLine(matchingLine);
    };

    const getPickingLabelsToPrint = async () => {
        try {
            setPickingLabelsLoading(true);
            await printAllPickingLabels(currentUser, releaseName);
        } catch (error) {
            setShowNotification({
                shown: true,
                error: 'error',
                title: 'Error Loading Picking Labels',
                message: `There was an error loading the picking labels for ${releaseName}. Please try again and if this issue persists contact your manager.`,
            });
        } finally {
            setPickingLabelsLoading(false);
        }
    };

    const processedAmount = () => {
        let processed = 0;
        releaseLineData.forEach((line) => {
            if (line.physicalStatus && line.traceStatus && line.formsStatus) {
                processed++;
            }
        });

        return `${processed} out of ${releaseLineData.length}`;
    };

    const clickableAction = (actionType) => {
        if (!actionType) {
            return <ExclamationCircleIcon className='h-10 text-gray-500' />;
        } else if (actionType === 'rejected') {
            return <XCircleIcon className='h-10 text-red-500' />;
        } else if (actionType === 'complete') {
            return <CheckCircleIcon className='h-10 text-green-500' />;
        }
    };

    return (
        <section className='flex flex-col'>
            {selectedPage === pageNames.RELEASE_LINES ? (
                loadingReleaseLines ? (
                    <div className='flex items-center justify-center h-screen'>
                        <LoadingSpinner className={'w-44'} spinnerSize={'32'} />
                    </div>
                ) : (
                    <div className='px-8'>
                        <header className='flex h-10 mt-10'>
                            <button
                                className='flex items-center justify-center w-24 h-10 px-3 my-auto text-lg text-white rounded-md bg-blue-primary hover:bg-blue-secondary'
                                onClick={clearSelectedRelease}
                            >
                                <ArrowLeftIcon className='w-6 h-6 mr-2' />
                                <span>Back</span>
                            </button>

                            {/* TODO: Add later for the timer functionality */}
                            {/* <div className='ml-auto text-left'>
                            <p>User Time/Part: {userTime}</p>
                            <p>Department Time/Part: {departmentTime}</p>
                            </div> */}
                        </header>
                        <article>
                            <div className='flex items-start justify-between pt-12'>
                                <div className='flex flex-col'>
                                    <h1 className='ml-1 text-3xl font-bold'>
                                        Release Lines for{' '}
                                        <a
                                            className='text-blue-800 underline hover:text-blue-400'
                                            href={releaseData.link}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            {releaseName}
                                        </a>
                                    </h1>
                                    <p className='ml-1'>Processed: {processedAmount()}</p>
                                </div>
                                <button
                                    className='h-10 px-3 my-auto text-lg text-white rounded-md bg-blue-primary hover:bg-blue-secondary'
                                    onClick={getPickingLabelsToPrint}
                                    disabled={pickingLabelsLoading}
                                >
                                    {pickingLabelsLoading ? (
                                        <LoadingSpinner className={'w-44'} spinnerSize={'7'} />
                                    ) : (
                                        'Print All Picking Labels'
                                    )}
                                </button>
                            </div>

                            <table className='w-full mt-1'>
                                <thead className='px-2 text-white bg-blue-primary'>
                                    <tr className='border-b'>
                                        <th className='w-16 pl-3 text-left'>Line</th>
                                        <th className='w-32 text-left'>INV</th>
                                        <th className='text-left w-52'>Part Number</th>
                                        <th className='text-left w-60'>Part Keyword</th>
                                        <th className='text-left w-60'>Serial Number</th>
                                        <th className='text-left w-36'>Condition Code</th>
                                        <th className='w-20 text-left'>Quantity</th>
                                        <th className='text-left w-28'>Owner Code</th>
                                        {/* TODO: <th className='w-32 text-left'>Time to Process</th> */}
                                        <th className='w-20 text-left'>Physical</th>
                                        <th className='w-20 text-left'>Trace</th>
                                        <th className='w-20 text-left'>Forms</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {releaseLineData.map((line) => (
                                        <tr
                                            key={line.releaseLineId}
                                            className='h-10 border-b border-gray-400 odd:bg-gray-200 even:bg-gray-50'
                                        >
                                            <td className='pl-3'>{line.lineNumber}</td>
                                            <td>
                                                <a
                                                    className='text-blue-800 underline hover:text-blue-400'
                                                    href={line.invLineLink}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                >
                                                    {line.invLineName}
                                                </a>
                                            </td>
                                            <td>{line.partName}</td>
                                            <td>{line.keyword}</td>
                                            <td>{line.serialNumber}</td>
                                            <td>{line.conditionCodeName}</td>
                                            <td>{line.quantity}</td>
                                            <td>{line.ownerCodeName}</td>
                                            {/* <td>{line.timeToProcess}</td> */}
                                            <td>
                                                <button
                                                    onClick={() => handlePageChange(pageNames.PHYSICAL, line.releaseLineId)}
                                                >
                                                    {clickableAction(line.physicalStatus)}
                                                </button>
                                            </td>
                                            <td>
                                                <button
                                                    onClick={() => handlePageChange(pageNames.TRACE, line.releaseLineId)}
                                                >
                                                    {clickableAction(line.traceStatus)}
                                                </button>
                                            </td>
                                            <td>
                                                <button
                                                    onClick={() => handlePageChange(pageNames.FORMS, line.releaseLineId)}
                                                >
                                                    {clickableAction(line.formsStatus)}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </article>
                    </div>
                )
            ) : null}
            {selectedPage === pageNames.PHYSICAL ? (
                <Physical
                    currentReleaseLine={currentReleaseLine}
                    currentUser={currentUser}
                    handlePageChange={handlePageChange}
                    releaseName={releaseName}
                    returnToReleaseLines={returnToReleaseLines}
                    setShowNotification={setShowNotification}
                />
            ) : null}
            {selectedPage === pageNames.TRACE ? (
                <Trace
                    currentReleaseLine={currentReleaseLine}
                    documentsToPrint={documentsToPrint}
                    setDocumentsToPrint={setDocumentsToPrint}
                    setShowNotification={setShowNotification}
                    releaseName={releaseName}
                    returnToReleaseLines={returnToReleaseLines}
                />
            ) : null}
            {selectedPage === pageNames.FORMS ? (
                <Forms
                    currentReleaseLine={currentReleaseLine}
                    returnToReleaseLines={returnToReleaseLines}
                    releaseData={releaseData}
                    releaseName={releaseName}
                />
            ) : null}
        </section>
    );
}
